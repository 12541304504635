<template>
  <base-modal-view title="Upload General Photo" @cancel="cancelClicked">
    <div>
      <form @submit.prevent="uploadPhoto" class="horizontal-spacing flex-column body-2">
        <p class="text-field-title margin-bottom-025 margin-top">General Photo</p>
        <div class="flex-row">
          <input
            style="width: 80%"
            accept="image/jpeg"
            type="file"
            class="vertical-spacing-half input-field"
            id="file"
            ref="photo"
            name="photo"
            v-on:change="handleFileUpload($event)"
          />
          <button
            type="button"
            name="photo"
            class="flex-end vertical-spacing-half no-button-style"
            title="Clear"
            v-on:click="handleFileClear"
          >
            X
          </button>
        </div>
        <p class="margin-top-05" :class="{ error: getFileSize() > 3 }">({{ getFileSize() }}/3MB Used)</p>
        <primary-button type="submit" class="margin-top" :title="loading ? 'Requesting...' : 'Request'" />
      </form>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

import { uploadGeneralPhoto } from "@/api/Form.js";
import { mapActions } from "vuex";

export default {
  name: "UploadPhotoModal",
  components: { PrimaryButton, BaseModalView },
  emits: ["cancel", "onPhoto"],
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      photo: null,
      error: null,
      loading: false
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    handleFileUpload: function (event) {
      this.photo = event.target.files[0];
    },
    handleFileClear: function () {
      this.$refs["photo"].value = null;
      this.photo = null;
    },
    getFileSize: function () {
      if (!this.photo) {
        return 0;
      }
      return (this.photo.size / (1024 * 1024)).toFixed(1);
    },
    uploadPhoto: function () {
      if (this.getFileSize() > 3) {
        alert(
          "Photo file size too large. Try scaling them down. An easy way to do this on phones is to screenshot the photo."
        );
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;

      uploadGeneralPhoto(this.form, this.photo)
        .then(response => {
          setTimeout(() => {
            this.loading = false;
            this.$emit("onPhoto", response);
          }, 2000);
        })
        .catch(error => {
          this.loading = false;
          this.addError(error);
        });
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.spacer {
  background-color: $DIVIDER_GRAY_COLOR;
  border-left: 1px solid $DIVIDER_GRAY_COLOR;
}
</style>
