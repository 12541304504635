<template>
  <base-modal-view title="Reasons Lost" @cancel="cancelClicked">
    <div>
      <form @submit.prevent="saveClicked" class="flex-column margin-top">
        <select class="dropdown-field" required v-model="reasonLost">
          <option value="">-- Select Reason --</option>
          <option v-for="reason in reasonsLost()" v-bind:key="reason" :value="reason">{{ reason }}</option>
          <option value="Other">Other</option>
        </select>

        <input
          v-if="reasonLost == 'Wants More Money/Negative Equity'"
          :required="reasonLost == 'Wants More Money/Negative Equity'"
          type="number"
          v-model="lostAmount"
          class="text-field margin-top"
          placeholder="Amount Customer Wanted"
        />

        <input
          v-if="reasonLost == 'Other'"
          :required="reasonLost == 'Other'"
          type="text"
          v-model="customReason"
          class="text-field margin-top"
          placeholder="Reason"
        />
        <primary-button type="submit" class="margin-top" title="Save" />
      </form>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { reasonsLost } from "../../../helpers/v2/formHelpers.js";

export default {
  name: "ReasonsLostModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      reasonLost: "",
      customReason: "",
      lostAmount: ""
    };
  },
  methods: {
    reasonsLost: function () {
      return reasonsLost;
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    saveClicked: function () {
      if (this.reasonLost == "Other") {
        this.$emit("onSubmit", { lostReason: this.customReason });
      } else if (this.reasonLost == "Wants More Money/Negative Equity") {
        this.$emit("onSubmit", { lostReason: this.reasonLost, lostAmount: this.lostAmount });
      } else {
        this.$emit("onSubmit", { lostReason: this.reasonLost });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
